import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

type Props = {
  message: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
  open: boolean;
  setOpen(value: boolean): void;
};

export const Snackbar = ({
  message,
  severity = 'info',
  open,
  setOpen,
}: Props): JSX.Element => {
  return (
    <>
      <MuiSnackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </MuiSnackbar>
    </>
  );
};
