import { ReactNode } from 'react';
import { Alert } from '@material-ui/lab';

type Props = {
  children?: ReactNode;
};

const defaultMessage = 'この画面を表示する権限がありません。';

export const PermissionError = ({
  children = defaultMessage,
}: Props): JSX.Element => {
  return <Alert severity="error">{children}</Alert>;
};
