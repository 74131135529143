import * as Sentry from '@sentry/nextjs';
import { useSession } from 'next-auth/client';

export const useErrorReport = (error: Error | undefined): void => {
  const [session, loading] = useSession();
  if (error === undefined || process.env.NODE_ENV === 'development') {
    return;
  }

  if (loading) return;

  if (session !== null) {
    Sentry.setUser({
      id: session.user.uuid,
      companyId: session.user.company ? session.user.company.uuid : null,
    });
  }
  Sentry.captureException(error);
};
