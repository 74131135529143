import { ReactNode } from 'react';
import { Alert } from '@material-ui/lab';
import { useErrorReport } from 'hooks/useErrorReport';

type Props = {
  children?: ReactNode;
  error?: Error | undefined;
};

const defaultMessage = 'エラーが発生しました。';

export const ProcessError = ({
  children = defaultMessage,
  error,
}: Props): JSX.Element => {
  useErrorReport(error);

  return <Alert severity="error">{children}</Alert>;
};
