import { Button, Box, IconButton } from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  LastPage,
  FirstPage,
} from '@material-ui/icons';
import { PaginationNav_PageInfoFragment } from 'generated/graphql';

export type PageInfo = PaginationNav_PageInfoFragment;

export type PaginationNavProps = {
  pageInfo: PageInfo;
  onPageChange: (page: number) => void;
};

export const PaginationNav = ({
  pageInfo,
  onPageChange,
}: PaginationNavProps): JSX.Element => {
  const { currentPage, isFirstPage, isLastPage, totalPages } = pageInfo;

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <IconButton
        size="small"
        onClick={() => onPageChange(1)}
        disabled={isFirstPage || totalPages === 0}
      >
        <FirstPage fontSize="small" />
      </IconButton>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={isFirstPage || totalPages === 0}
        startIcon={<ChevronLeft />}
      >
        前のページ
      </Button>
      <Box ml={2} mr={2} fontSize="1rem">
        {totalPages === 0 ? '-' : `${currentPage} / ${totalPages}`}
      </Box>
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={isLastPage || totalPages === 0}
        endIcon={<ChevronRight />}
      >
        次のページ
      </Button>
      <IconButton
        size="small"
        onClick={() => onPageChange(totalPages)}
        disabled={isLastPage || totalPages === 0}
      >
        <LastPage fontSize="small" />
      </IconButton>
    </Box>
  );
};
