import { Box, CircularProgress } from '@material-ui/core';

export const LoadingSpinner = (): JSX.Element => {
  return (
    <Box display="flex" justifyContent="center">
      <Box textAlign="center" mt={8}>
        <CircularProgress />
        <p>データの読み込み中です...</p>
      </Box>
    </Box>
  );
};
